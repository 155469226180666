import config from './config'
import location from './location'
import customer from './customer'
import auth from './auth'
import customerAddress from './customer/address'
import content from './content'
import faq from './faq'
import form from './form'
import blog from './blog'


const resources = {
  config,
  location,



  auth,
  customer,
  customerAddress,

  content,
  faq,
  form,

  blog,
}

export type API = {
  [K in keyof typeof resources]: ReturnType<typeof resources[K]>
}

export default resources
