// eslint-disable-next-line import/no-duplicates
import { format, setDay, isWithinInterval, isBefore } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { nl, enGB, fr } from 'date-fns/locale'
import { trimString } from '~/lib/utilities'

const WEEK_DICTIONARY = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
} as const
const LANGUAGE_CODE_DICTIONARY = {
  NL: nl,
  FR: fr,
  EN: enGB,
} as const

export const inRange = (startDate?: string, endDate?: string): boolean => {
  const now = new Date()
  // '0001-01-01T00:00:00.0000000' means empty date
  const startdate =
    startDate && startDate !== '0001-01-01T00:00:00.0000000'
      ? new Date(startDate)
      : undefined
  const enddate =
    endDate && endDate !== '0001-01-01T00:00:00.0000000'
      ? new Date(endDate)
      : undefined

  if (startdate && !enddate) {
    return isBefore(startdate, now)
  }
  if (!startdate && enddate) {
    return isBefore(now, enddate)
  }
  if (startdate && enddate) {
    return isWithinInterval(now, {
      start: startdate,
      end: enddate,
    })
  }
  return true
}

export const formatTimeString = (timeString: string) => {
  // format 1900 to 19:00
  return `${timeString.slice(0, 2)}:${timeString.slice(2)}`
}

export const parseISO8601Duration = (
  duration: string,
  format: string = 'hh:mm'
): string => {
  const REGEX = /P(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/
  const [, hours, mins, secs] = duration.match(REGEX) || []
  // support two kinds of delimiter (':' and '.')
  let delimiter = ':'
  if (format?.indexOf('.') !== -1) {
    delimiter = '.'
  }
  const formatArr = format.split(delimiter)
  const fullTimeArr: string[] = []
  if (formatArr?.includes('hh')) {
    fullTimeArr.push(hours || '00')
  }
  if (formatArr?.includes('mm')) {
    fullTimeArr.push(mins || '00')
  }
  if (formatArr?.includes('ss')) {
    fullTimeArr.push(secs || '00')
  }

  return fullTimeArr.length
    ? fullTimeArr
        .map((num) => (num.length < 2 ? `0${num}` : num))
        .join(delimiter)
    : ''
}

export const getWeekDay = (day: string): number => {
  return WEEK_DICTIONARY[day] ?? -1
}

export const localeWeekDay = (
  day: number,
  languageCode: keyof typeof LANGUAGE_CODE_DICTIONARY = 'NL'
) => {
  if (day < 0 || day > 6) return ''

  return format(setDay(new Date(), day), 'EEEE', {
    locale: LANGUAGE_CODE_DICTIONARY[languageCode],
  })
}


export const getCurrentTimeString = () => {
  const now = new Date()
  const hh = trimString(now.getHours().toString(), 2)
  const mm = trimString(now.getMinutes().toString(), 2)
  const ss = trimString(now.getSeconds().toString(), 2)
  return `${hh}:${mm}:${ss}`
}

export const getSecFromTime = (time: string) => {
  if (!time) return 0
  const timeArr = time.split(':').map((i) => Number(i))
  const h = timeArr[0] ?? 0
  const m = timeArr[1] ?? 0
  const s = timeArr[2] ?? 0
  return h * 3600 + m * 60 + s
}

export const isAfterTime = (time: string, timeToCompare: string) => {
  return getSecFromTime(time) > getSecFromTime(timeToCompare)
}

export const isWithinTime = (
  time: string,
  startTime: string,
  endTime: string
) => {
  const timeSec = getSecFromTime(time)
  return (
    timeSec >= getSecFromTime(startTime) && timeSec <= getSecFromTime(endTime)
  )
}

export const getNextYearDate = (date = new Date()) => {
  return new Date(new Date().setFullYear(date.getFullYear() + 1))
}
